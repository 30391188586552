.btmCnt {
    width: 500px;
}

.BtmBranches {
    background-image: url("../Images/BottomBranches.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 500px;
    height: 570px;
    position: absolute;
    top: 381px;
    z-index: -1;
}

@media only screen and (max-width: 500px) {
    .btmCnt {
        width: 500px;
    }
    
    .BtmBranches {
        background-image: url("../Images/BottomBranches.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 350px;
        height: 570px;
        position: absolute;
        top: 256px;
        z-index: -1;
    }
}