.TopBranches {
    background-image: url("../Images/TopBranches.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    width: 500px;
    height: 570px;
    position: absolute;
    top: 51px;
    z-index: 1;
}

@media only screen and (max-width: 500px) {
    .TopBranches {
        background-image: url("../Images/TopBranches.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top;
        width: 350px;
        height: 570px;
        position: absolute;
        top: 26px;
        z-index: 1;
    }
}