.CardBackground {
    background-color: white;
    position: absolute;
    width: 500px;
    height: 900px;
    top: 50px;
    z-index: -5;
}

@media only screen and (max-width: 500px) {
    .CardBackground {
        background-color: white;
        position: absolute;
        width: 350px;
        height: 800px;
        top: 25px;
        z-index: -5;
    }
}