.BottomContainer {
    margin-top: 400px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: lighter;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

button:hover {
    cursor: pointer;    
}

.rsvpBtn, .regBtn {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: transparent;
    border-radius: 5px;
    width: 200px;
    height: 30px;
    color: rgb(123, 93, 65);
    border: rgb(123, 93, 65) solid 1px;
    z-index: 2;
}

.BtnContainer {
    margin: 10px;
    z-index: 2;
}

@media only screen and (max-width: 500px) {
    .BottomContainer {
        margin-top: 25rem;
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-weight: lighter;
        z-index: 2;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    
    .rsvpBtn, .regBtn {
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        background-color: transparent;
        border-radius: 5px;
        width: 175px;
        height: 30px;
        color: rgb(123, 93, 65);
        border: rgb(123, 93, 65) solid 1px;
        z-index: 2;
    }
    
    .BtnContainer {
        margin: 10px;
        z-index: 2;
    }
}