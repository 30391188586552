.InformationCnt {
    color: rgb(123, 93, 65);
    z-index: 1;
}

.names {
    font-size: 2rem;
    padding: 10px;
}

.EventInfo {
    padding: 5px;
}

@media only screen and (max-width: 500px) {
    .InformationCnt {
        color: rgb(123, 93, 65);
        z-index: 1;
    }
    
    .names {
        font-size: 2rem;
        padding: 5px;
    }
    
    .EventInfo {
        padding: 5px;
        font-size: 0.75rem;
    }
}