.App {
  text-align: center;
  border: 1px solid black;
  width: 500px;
  height: 900px;
  margin-top: 50px;
  background-color: transparent;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}

@media only screen and (max-width: 500px) {
  .App {
    text-align: center;
    border: 1px solid black;
    width: 350px;
    height: 800px;
    margin-top: 25px;
    background-color: transparent;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
  }
}