.Cradle {
    background-image: url("../Images/Crib.png");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: top;
    width: 500px;
    height: 450px;
    position: absolute;
}

@media only screen and (max-width: 500px) {
    .Cradle {
        background-image: url("../Images/Crib.png");
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: top;
        width: 350px;
        height: 450px;
        position: absolute;
    }
}