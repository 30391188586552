.formCnt {
    display: flex;
    justify-content: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: rgb(123, 93, 65);
}

.formCnt form {
    display: flex;
    flex-direction: column;
    color: rgb(123, 93, 65);
}


.formCnt input {
    margin-bottom: 25px;
}

.formCnt button {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    background-color: transparent;
    border-radius: 5px;
    width: 200px;
    height: 30px;
    align-self: center;
    margin-bottom: 10px;
    color: rgb(123, 93, 65);
    border: rgb(123, 93, 65) solid 1px;
}

.formLabel {
    display: flex;
    justify-content: space-evenly;
}

.formItem {
    width: 150px;
}

@media only screen and (max-width: 500px) {
    .formCnt {
        display: flex;
        justify-content: center;
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size: 1rem;
        color: rgb(123, 93, 65);
    }
    
    .formCnt form {
        display: flex;
        flex-direction: column;
        color: rgb(123, 93, 65);
    }
    
    
    .formCnt input {
        margin-bottom: 15px;
    }
    
    .formCnt button {
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        background-color: transparent;
        border-radius: 5px;
        width: 175px;
        height: 30px;
        align-self: center;
        margin-bottom: 10px;
        color: rgb(123, 93, 65);
        border: rgb(123, 93, 65) solid 1px;
    }
    
    .formLabel {
        display: flex;
        justify-content: space-evenly;
    }
    
    .formItem {
        width: 125px;
    }
}