.Title {
    display: flex;
    justify-content: center;
    font-family: 'League Script', 'Sacramento', cursive;
    font-size: 1rem;
    color: rgb(123, 93, 65);
    margin-top: 100px;
}

@media only screen and (max-width: 500px) {
    .Title {
        display: flex;
        justify-content: center;
        font-family: 'League Script', 'Sacramento', cursive;
        font-size: 1rem;
        color: rgb(123, 93, 65);
        position: absolute;
        top: -10px;
        width: 100%;
    }
}